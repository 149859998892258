


























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { routeList } from './routeList'
import { GeneralRoutes } from '@/router/routes/general'

@Component({
  methods: {
    __
  }
})
export default class Sidebar extends Vue {
  @Prop({ default: null }) sidebarStatus?: boolean | null
  routes = routeList
  GeneralRoutes = GeneralRoutes

  private toggleSidebar():void {
    this.$root.$emit('sidebarStatus', !this.sidebarStatus)
  }

  collapseMobileSidebar(): void {
    if (window.innerWidth < 1240) {
      this.$root.$emit('sidebarStatus', true)
    }
  }
}
