

















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AuthLayout extends Vue {
  company = ''

  mounted():void {
    this.company = process.env.VUE_APP_COMPANY || 'wi'
  }

  get logo(): string {
    if (this.company === 'wedev') {
      return require('@/assets/images/logo-wedev.svg');
    }
    return require('@/assets/images/logo.svg');
  }
}
