










import { Component, Prop, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import { GeneralRoutes } from '@/router/routes/general';

@Component({
  components: { Breadcrumbs }
})
export default class PageHeader extends Vue {
  @Prop() title!: string
  @Prop() subTitle!: string
  @Prop() subTitleNote!: string
  @Prop({ default: '' }) classes!: string
  @Prop({ default: false }) loading!: boolean
  @Prop() goBackAction!: () => void

  GeneralRoutes = GeneralRoutes
}
