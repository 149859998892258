



























































import { AuthRoutes } from '@/router/routes/auth'
import http from '@/shared/helpers/http'
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import __ from '@/helpers/__'
import { GeneralRoutes } from '@/router/routes/general'
import User from '@/modules/user/models/user.model'
import { UserActions } from '@/store/modules/user/user.actions'
import { UserGetters } from '@/store/modules/user/user.getters'
import UserDataModal from '@/shared/components/UserDataModal.vue'
import PageHeader from '@/components/layout/PageHeader.vue';
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import Sidebar from '@/components/layout/Sidebar.vue';

@Component({
  components: {
    Sidebar,
    Breadcrumbs,
    PageHeader,
    UserDataModal
  },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  },
  methods: {
    __
  }
})
export default class Topnav extends Vue {
  @Prop({ default: null }) sidebarStatus?: boolean | null

  selectedParentMenu: string = ''
  isMenuOver: boolean = false
  GeneralRoutes = GeneralRoutes
  user!: User
  heading: string | null = null
  subheading: string | null = null
  subheadingNote: string | null = null

  mounted(): void {
    this.toggleSelectedParentMenu()

    document.addEventListener('click', this.returnSelectedParentMenu)
  }

  created(): void {
    if (typeof this.$route.meta?.heading !== 'undefined') {
      this.heading = this.$route.meta.heading
    }

    if (typeof this.$route.meta?.subheading !== 'undefined') {
      this.subheading = this.$route.meta.subheading
    }

    if (typeof this.$route.meta?.subheading !== 'undefined') {
      this.subheadingNote = this.$route.meta.subheadingNote
    }

    this.$root.$on('headingChange', (data: HeadingPayload) => {
      if (typeof data.heading !== 'undefined') {
        this.heading = data.heading
      }
      if (typeof data.subheading !== 'undefined') {
        this.subheading = data.subheading
      }
      if (typeof data.subheadingNote !== 'undefined') {
        this.subheadingNote = data.subheadingNote
      }
    })
  }

  @Watch('$route', { deep: true }) onRouteChange(newRoute: any, oldRoute: any): void {
    if (newRoute.name === oldRoute.name) {
      return
    }
    this.heading = newRoute.meta?.heading || null
    this.subheading = newRoute.meta?.subheading || null
    this.subheadingNote = newRoute.meta?.subheadingNote || null
  }

  editUserData(): void {
    const ref: any = this.$refs.userModal
    ref.editProfile()
  }

  returnSelectedParentMenu(): void {
    if (!this.isMenuOver) {
      this.toggleSelectedParentMenu()
    }
  }

  toggleSelectedParentMenu(): void {
    const currentParentUrl = this.$route.path.split('/').filter(x => x !== '')[1]

    if (typeof currentParentUrl !== 'undefined') {
      this.selectedParentMenu = currentParentUrl.toLowerCase()
    } else {
      this.selectedParentMenu = 'dashboards'
    }
  }

  get userInitials(): string {
    return this.user.firstName.substring(0, 1) + this.user.lastName.substring(0, 1)
  }

  logout(): void {
    http
      .post('auth/logout')
      .then(() => {
        this.$store.dispatch(UserActions.logout)
        this.$router.push({ name: AuthRoutes.login })
      })
      .catch(err => {
        console.log('err = ', err)
      })
  }

  toggleSidebar() {
    this.$root.$emit('sidebarStatus', !this.sidebarStatus)
  }
}

interface HeadingPayload {
  heading?: string
  subheading?: string
  subheadingNote?: string
}
