import { GeneralRoutes } from '@/router/routes/general'
import { RouteInfo } from '@/shared/interfaces/route-info.interface';

export const routeList: RouteInfo[] = [
  {
    name: 'dashboard',
    icon: require('@/assets/images/sidebar/home.svg'),
    to: GeneralRoutes.dashboard
  },
  {
    name: 'clients',
    icon: require('@/assets/images/sidebar/person.svg'),
    to: GeneralRoutes.clientsIndex
  },
  {
    name: 'requests',
    icon: require('@/assets/images/sidebar/offer.svg'),
    to: GeneralRoutes.requestByStatus
  },
  {
    name: 'invoices',
    icon: require('@/assets/images/sidebar/invoice.svg'),
    children: [
      {
        name: 'invoices',
        to: GeneralRoutes.invoicesIndex
      },
      {
        name: 'prepaid-invoices',
        to: GeneralRoutes.prepaidInvoicesIndex
      },
      {
        name: 'invoice-templates',
        to: GeneralRoutes.invoiceTemplatesIndex
      }
    ]
  },
  {
    name: 'offers',
    icon: require('@/assets/images/sidebar/offer.svg'),
    children: [
      {
        name: 'offers',
        to: GeneralRoutes.offersIndex
      },
      {
        name: 'offer-templates',
        to: GeneralRoutes.offerTemplatesIndex
      }
    ]
  },
  {
    name: 'projects',
    icon: require('@/assets/images/sidebar/offer.svg'),
    to: GeneralRoutes.projectsIndex
  },
  {
    name: 'procurements',
    icon: require('@/assets/images/sidebar/offer.svg'),
    children: [
      {
        name: 'procurements',
        to: GeneralRoutes.procurementIndex
      },
      {
        name: 'procurement-search',
        to: GeneralRoutes.procurementSearch
      },
      {
        name: 'procurement-settings',
        to: GeneralRoutes.procurementSettings
      }
    ]
  },
  {
    name: 'documents',
    icon: require('@/assets/images/sidebar/document.svg'),
    children: [
      {
        name: 'documents-index',
        to: GeneralRoutes.documentsIndex,
        query: { sort: '-date' }
      },
      {
        name: 'documents-templates-index',
        to: GeneralRoutes.documentsTemplatesIndex
      }
    ]
  },
  {
    name: 'settings',
    icon: require('@/assets/images/sidebar/settings.svg'),
    children: [
      {
        name: 'settings-documents',
        to: GeneralRoutes.settingsDocuments
      },
      {
        name: 'settings-general',
        to: GeneralRoutes.settingsGeneral
      },
      {
        name: 'reminder-templates',
        to: GeneralRoutes.reminderTemplatesIndex
      },
      {
        name: 'default-templates',
        to: GeneralRoutes.defaultTemplatesIndex
      }
    ]
  },
  {
    name: 'payments',
    icon: require('@/assets/images/sidebar/payment.svg'),
    to: GeneralRoutes.paymentsIndex
  },
  {
    name: 'services',
    icon: require('@/assets/images/sidebar/product.svg'),
    to: GeneralRoutes.servicesIndex
  },
  {
    name: 'users',
    icon: require('@/assets/images/sidebar/person.svg'),
    to: GeneralRoutes.usersIndex
  },
  {
    name: 'workers',
    icon: require('@/assets/images/sidebar/person.svg'),
    to: GeneralRoutes.workersIndex
  }
]
