























import { Component, Vue } from 'vue-property-decorator';
import AuthLayout from '@/views/auth/AuthLayout.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base';
import Field from '@/shared/classes/components/form/field';
import { FieldTypes } from '@/shared/components/form/field-types';
import { AuthRoutes } from '@/router/routes/auth';

@Component({
  components: { Form, AuthLayout },
  methods: { __ }
})
export default class LoginUser extends Vue {
  AuthRoutes = AuthRoutes
  form: FormBase = new FormBase()
    .setEndpoint('auth/password/reset')
    .setNoSubmitButton(true)
    .setOnSuccess(() => {
      this.$router.push({ name: AuthRoutes.login });
    })
    .setFields([
      new Field()
        .setKey('email')
        .setType(FieldTypes.email)
        .setTitle(__('views.auth.email'))
    ])
}
